import { Button, Card, Checkbox, Form, Input, InputNumber } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import pluralize from 'pluralize';
import { ANY_FIXME } from 'types/common';
import ReactDraft from 'utils/ReactDraft';
import { CustomDatePicker } from 'components/inputs/CustomDatePicker';
import { BooleanSelector } from 'components/inputs/BooleanSelector';
import { generateLabel } from 'utils/GenerateLabelAndIcon';
import { capitalize } from 'lodash';
import { ImageUploaderModal } from 'components/inputs/ImageUploaderModal';
import { InputGeoField } from './InputGeoField';
import FixedListSelector from 'components/inputs/FixedListSelector';
import { DynamicFormInsideArrayList } from 'components/groupedInput/DynamicFormList';
import { ObjectFieldsGenerator } from './ObjectFieldsGenerator';

dayjs.extend(relativeTime);

export function RepeatedFieldsGenerator({ field_item, parent_path = [], ...props }) {
  //console.log('RepeatedFieldsGenerator : ', field_item.identifier, parent_path);

  const getValidationRules = (item) => {
    const required = item?.validation?.required && !item?.validation?.is_password;
    return [
      {
        required,
        message: `${capitalize(item.identifier)} is required`,
      },
    ];
  };

  // const SingleField = (fieldItem) => {
  //   const item = fieldItem?.value ?? {};
  //   return renderFormItem(item, item?.identifier);
  // };

  const SubFieldsList = field_item?.sub_field_info ?? [];
  //console.log('field_item : ', field_item);

  const disableInput = field_item?.system_generated;

  const getFormField = (field_item, index, name?: ANY_FIXME[]) => {
    const field_Type = field_item?.field_type;

    switch (field_Type) {
      case 'text':
        if (field_item?.system_generated) {
          return <Input addonBefore="UUID" title="some" defaultValue={uuidv4()} disabled />;
        }
        return <Input />;

      case 'multiline':
        return <ReactDraft />;
      case 'date':
        return <CustomDatePicker />;
      case 'number':
        return <InputNumber style={{ width: '100%' }} />;
      case 'boolean':
        return <BooleanSelector />;
      case 'media':
        return <ImageUploaderModal field_item={field_item} />;
      case 'geo':
        return <InputGeoField field_item={field_item} namePath={name} />;
      case 'object':
        return (
          <ObjectFieldsGenerator
            field_item={field_item}
            parent_path={[index]}
            disabled={disableInput}
          />
        );
      case 'repeated':
        //return <div>Nested items</div>;
        console.log('Repeated Field : ', field_item);
        return (
          <RepeatedFieldsGenerator
            field_item={field_item}
            parent_path={[index]}
            disabled={disableInput}
          />
        );
      case 'list':
        const list = field_item?.validation?.fixed_list_elements || [];
        const multiChoice = field_item?.validation?.is_multi_choice;

        if (list.length > 0 && !multiChoice) {
          return <FixedListSelector list={list} />;
        }
        if (list.length > 0 && multiChoice) {
          return <Checkbox.Group options={list} />;
        }
        if (list.length === 0 && !multiChoice) {
          return <DynamicFormInsideArrayList field_item={field_item} namePath={name} />;
        }
        break;
      default:
        break;
    }
  };

  const renderRepeatedFormItems = (FieldsArray, index) =>
    FieldsArray.map((item, i) => {
      const list = item?.validation?.fixed_list_elements || [];
      const multiChoice = item?.validation?.is_multi_choice;

      if (item?.field_type === 'multiline') {
        return (
          <Form.Item
            key={i}
            //name={_path}
            name={[index, `${item.identifier}`, 'html']}
            label={generateLabel(item)}
            tooltip={item.description}
            rules={getValidationRules(item)}
          >
            {getFormField(item, index)}
          </Form.Item>
        );
      }
      if (
        item?.field_type === 'geo' ||
        (item?.field_type === 'list' && list.length === 0 && !multiChoice)
      ) {
        // geo && dynamic list
        console.log('geo field : ', item);
        return (
          <Form.Item
            key={i}
            name={[index, `${item.identifier}`]}
            label={generateLabel(item)}
            tooltip={item.description}
          >
            {getFormField(item, index, [index, `${item.identifier}`])}
          </Form.Item>
        );
      }

      var __path = [index, `${item.identifier}`];
      /* if (parent_path.length > 0) {
        __path = [...parent_path, `${field_item.identifier}`, index, `${item.identifier}`];
      } */
      //console.log('Item Path : ', __path);

      return (
        <Form.Item
          key={i}
          name={__path}
          label={generateLabel(item)}
          tooltip={item.description}
          rules={getValidationRules(item)}
        >
          {getFormField(item, index)}
        </Form.Item>
      );
    });

  var _path = ['form', `${field_item.identifier}`];
  if (parent_path.length > 0) {
    _path = [...parent_path, `${field_item.identifier}`];
  }

  //console.log('List Path : ', _path);

  return (
    <Form.List name={_path} {...props}>
      {/* formList name must be same as parent to catch initial value */}
      {(fields, { add, remove }) => (
        <div style={{ marginTop: 10 }}>
          {fields.map((field, index) => (
            <Card
              headStyle={{
                borderLeftColor: '#e73a55',
                borderLeftWidth: '2px',
                borderLeftStyle: 'solid',
              }}
              bodyStyle={{
                borderLeftColor: '#e73a55',
                borderLeftWidth: '2px',
                borderLeftStyle: 'solid',
              }}
              title={`${capitalize(field_item.identifier)} Section #${index + 1}`}
              extra={
                <div>
                  {fields.length >= 1 ? (
                    <Button onClick={() => remove(field.name)} icon={<MinusCircleOutlined />}>
                      Remove Field
                    </Button>
                  ) : null}
                </div>
              }
              style={{ marginBottom: '15px' }}
            >
              <div key={field.key}>{renderRepeatedFormItems(SubFieldsList, index)}</div>
            </Card>
          ))}
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => {
                add();
              }}
              style={{ width: '100%' }}
            >
              <PlusOutlined /> Add {pluralize(`${field_item.label}`, 1)}
            </Button>
          </Form.Item>
        </div>
      )}
    </Form.List>
  );
}
