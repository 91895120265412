import { Card, Checkbox, Form, Input, InputNumber } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { ANY_FIXME } from 'types/common';
import ReactDraft from 'utils/ReactDraft';
import { CustomDatePicker } from 'components/inputs/CustomDatePicker';
import { BooleanSelector } from 'components/inputs/BooleanSelector';
import { generateLabel } from 'utils/GenerateLabelAndIcon';
import { capitalize } from 'lodash';
import { ImageUploaderModal } from 'components/inputs/ImageUploaderModal';
import { InputGeoField } from './InputGeoField';
import FixedListSelector from 'components/inputs/FixedListSelector';
import { DynamicFormInsideArrayList } from 'components/groupedInput/DynamicFormList';

dayjs.extend(relativeTime);

export function ObjectFieldsGenerator({ field_item, parent_path = [], ...props }) {
  const getValidationRules = (item) => {
    const required = item?.validation?.required && !item?.validation?.is_password;
    return [
      {
        required,
        message: `${capitalize(item.identifier)} is required`,
      },
    ];
  };

  // const SingleField = (fieldItem) => {
  //   const item = fieldItem?.value ?? {};
  //   return renderFormItem(item, item?.identifier);
  // };

  const getFormField = (field_item, name?: ANY_FIXME[]) => {
    const field_Type = field_item?.field_type;

    switch (field_Type) {
      case 'text':
        if (field_item?.system_generated) {
          return <Input addonBefore="UUID" title="some" defaultValue={uuidv4()} disabled />;
        }
        return <Input />;

      case 'multiline':
        return <ReactDraft />;
      case 'date':
        return <CustomDatePicker />;
      case 'number':
        return <InputNumber style={{ width: '100%' }} />;
      case 'boolean':
        return <BooleanSelector />;
      case 'media':
        return <ImageUploaderModal field_item={field_item} />;
      case 'geo':
        return <InputGeoField field_item={field_item} namePath={name} />;
      case 'list':
        const list = field_item?.validation?.fixed_list_elements || [];
        const multiChoice = field_item?.validation?.is_multi_choice;

        if (list.length > 0 && !multiChoice) {
          return <FixedListSelector list={list} />;
        }
        if (list.length > 0 && multiChoice) {
          return <Checkbox.Group options={list} />;
        }
        if (list.length === 0 && !multiChoice) {
          return <DynamicFormInsideArrayList field_item={field_item} namePath={name} />;
        }
        break;
      default:
        break;
    }
  };

  const renderObjectFormItems = (fieldItem, FieldsArray) => {
    return FieldsArray.map((item, i) => {
      const list = item?.validation?.fixed_list_elements || [];
      const multiChoice = item?.validation?.is_multi_choice;

      var _path = ['form', `${fieldItem.identifier}`, `${item.identifier}`];
      if (parent_path.length > 0) {
        _path = [...parent_path, `${fieldItem.identifier}`, `${item.identifier}`];
      }

      if (item?.field_type === 'multiline') {
        return (
          <Form.Item
            key={i}
            name={[..._path, 'html']}
            label={generateLabel(item)}
            tooltip={item.description}
            rules={getValidationRules(item)}
          >
            {getFormField(item)}
          </Form.Item>
        );
      }
      if (
        item?.field_type === 'geo' ||
        (item?.field_type === 'list' && list.length === 0 && !multiChoice)
      ) {
        // geo && dynamicList
        return (
          <Form.Item key={i} name={_path} label={generateLabel(item)} tooltip={item.description}>
            {getFormField(item, ['form', `${fieldItem.identifier}`, `${item.identifier}`])}
          </Form.Item>
        );
      }

      console.log('Item Path ->', _path);

      return (
        <Form.Item
          key={i}
          name={_path}
          label={generateLabel(item)}
          tooltip={item.description}
          rules={getValidationRules(item)}
        >
          {getFormField(item)}
        </Form.Item>
      );
    });
  };

  // console.log('Log: renderFormItems -> field', field)

  const ObjecItemList = field_item?.sub_field_info ?? []; // this object item list is the sub field list

  return (
    <div style={{ marginTop: 10 }}>
      <Card
        headStyle={{
          borderBottomColor: 'black',
          borderBottomWidth: '10px',
          backgroundColor: '#FAFAFA',
        }}
        style={{ marginBottom: '15px' }}
        bodyStyle={{
          borderLeftColor: '#e73a55',
          borderLeftWidth: '2px',
          borderLeftStyle: 'solid',
        }}
        //
      >
        {renderObjectFormItems(field_item, ObjecItemList)}
      </Card>
    </div>
  );
}
