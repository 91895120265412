import { useContext, useState } from 'react';

import { Button, Form, Input } from 'antd';

import { ContentContext } from 'context/ContentContext';

import styles from '../modelContainer/modelContainer.module.scss';
import { Field_Operation_Type_Enum, useModelFieldOperationMutation } from 'generated/graphql';
import { successMessage } from 'utils/message';
import { FieldOperationDrawerComponentProps } from '../interface/interface';
import { capitalize } from 'lodash';
import { GET_FORM_GENERATION_DATA } from '../../../../graphql/queries/contents';

const validateMessages = {
  // eslint-disable-next-line no-template-curly-in-string
  required: '${label} is required!',
};

const FieldOperationDrawerComponent = ({
  fieldData,
  type,
  onFieldOperationDrawerClose,
  objectIdentifier,
}: FieldOperationDrawerComponentProps) => {
  const { state } = useContext(ContentContext);
  const [newIdentifier, setNewIdentifier] = useState('');

  const { label, identifier } = fieldData;

  const updateModelCacheData = (cache, { data }) => {
    //console.log('resdata', data);
    const resData = data?.modelFieldOperation;
    const { repeated_group_identifier } = resData || {};
    const { serial } = resData;

    const query = {
      query: GET_FORM_GENERATION_DATA,
      variables: {
        model_name: `${state.target}`,
      },
    };

    const existingData = cache.readQuery({
      ...query,
    });

    let fieldsArray = [];
    if (repeated_group_identifier) {
      fieldsArray = [
        ...existingData?.projectModelsInfo[0]?.fields?.map((field) => {
          if (field.identifier === repeated_group_identifier) {
            return {
              ...field,
              sub_field_info:
                field?.sub_field_info.map((item) => {
                  if (item.identifier === resData.identifier) {
                    return { ...item, ...resData };
                  }
                  return item;
                }) ?? [],
            };
          }
          if (field.serial === serial) return { ...field, ...resData };
          return field;
        }),
      ];
    } else {
      fieldsArray = [...(existingData?.projectModelsInfo[0]?.fields || []), resData]; // Create a new array with resData appended
    }

    var _data = {
      projectModelsInfo: existingData?.projectModelsInfo.map((info, index) =>
        index === 0 ? { ...info, fields: fieldsArray } : info
      ),
    };

    cache.writeQuery({
      ...query,
      data: _data,
    });
  };

  const [modelFieldOperation, { loading }] = useModelFieldOperationMutation({
    onCompleted: (data) => {
      successMessage(`Field ${capitalize(type)} Successful`);
      // form.resetFields()
      onFieldOperationDrawerClose();
    },
    update: updateModelCacheData,
  });

  const onFinish = async (values) => {
    let _type = Field_Operation_Type_Enum.Rename;
    switch (type) {
      case 'rename':
        _type = Field_Operation_Type_Enum.Rename;
        break;
      case 'duplicate':
        _type = Field_Operation_Type_Enum.Duplicate;
        break;
      default: // default rename !! why ?
        _type = Field_Operation_Type_Enum.Rename;
    }
    await modelFieldOperation({
      variables: {
        type: _type,
        model_name: state.target,
        field_name: identifier, // old_identifier
        repeated_group_identifier: objectIdentifier || undefined,
        new_name: values.new_name,
      },
    });
  };

  const onFormValueChange = (values) => {
    const label = values?.new_name ?? '';
    if (label) {
      setNewIdentifier(
        label
          .toLocaleLowerCase()
          .trim()
          .replace(/[^A-Z0-9]+/gi, '_') ?? ''
      );
    }
  };

  return (
    <div className={styles.connectionSettingDrawerContainer}>
      <Form
        name="field_setting"
        layout="vertical"
        onFinish={onFinish}
        validateMessages={validateMessages}
        onValuesChange={onFormValueChange}
        initialValues={{ remember: true }}
      >
        <div className={`${styles.topContent} mt-2`}>
          <Form.Item
            name="new_name"
            initialValue={label}
            label="New Label"
            rules={[{ required: true }]}
          >
            <Input placeholder="The name of the field, Ex: Name, Title, etc" />
          </Form.Item>
          <Form.Item label="Identifier (Auto Generated)">
            <Input placeholder="new_name" value={newIdentifier || identifier} disabled />
          </Form.Item>
        </div>

        <Button
          type="primary"
          htmlType="submit"
          className={styles.updateFieldBtn}
          loading={loading}
        >
          {type.toUpperCase()}
        </Button>
      </Form>
    </div>
  );
};

export default FieldOperationDrawerComponent;
