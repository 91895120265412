import pluralize from 'pluralize';

import { Button, Divider, Drawer, Empty, Typography } from 'antd';
import { ContentContext } from 'context/ContentContext';
import { capitalize } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { PlusCircleOutlined, TagsOutlined } from '@ant-design/icons';
import EditRelationForm from './EditRelationForm';
import TableGenerator from './TableGenerator';
import MainLayout from 'components/layouts/MainLayout';
import { useNavigate, useParams } from 'react-router-dom';
import EditAndCreateContentForm from './EditAndCreateContentForm';
import FakeContentForm from './FakeContentForm';
// import { FormInstance } from 'antd/es/form';
// import AddContentForm from './AddContentForm';

const { Text } = Typography;

type ContentsProps = {
  connection?: any;
  fetchIntersectResult?: boolean;
  fetchNewSelectedRowKeys?: any;
  loadingTag?: boolean;
};

const Contents = ({
  connection,
  fetchIntersectResult,
  fetchNewSelectedRowKeys,
  loadingTag,
}: ContentsProps) => {
  const params = useParams();

  let modelName = '';
  const { state } = useContext(ContentContext);
  if (!state.target) {
    modelName = state?.target;
  } else {
    modelName = connection?.model;
  }

  console.log('connection', connection);
  console.log('target', state);
  console.log('params model', params?.model);

  const [drawerTitle, setDrawerTitle] = useState(undefined);
  const [drawerComponent, setDrawerComponent] = useState(undefined);

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [contentData, setContentData] = useState<any>({});

  const [newSelectedRowKeys, setNewSelectedRowKeys] = useState([]);

  const [newRowData, setNewRowData] = useState(undefined);

  const onDrawerClose = () => {
    setDrawerVisible(false);
  };

  const actualRelationName = capitalize(
    connection?.relation_type === 'has_many' ? pluralize(modelName) : modelName
  );

  useEffect(() => {
    switch (contentData?.action) {
      case 'create':
        setDrawerTitle(`Create A New ${capitalize(modelName)}`);
        setDrawerComponent(
          <EditAndCreateContentForm
            contentData={contentData}
            onContentCreated={(newRow) => {
              setNewRowData(newRow);
              setDrawerVisible(false);
            }}
            formType="create"
          />
        );
        break;
      // case 'create':
      //   setDrawerTitle(`Create A New ${capitalize(modelName)}`);
      //   setDrawerComponent(
      //     <AddContentForm
      //       contentData={contentData}
      //       onContentCreated={(newRow) => {
      //         setNewRowData(newRow);
      //         setDrawerVisible(false);
      //       }}
      //     />
      //   );
      //   break;
      case 'fake':
        setDrawerTitle(`Populate Fake Data to ${capitalize(modelName)}`);
        setDrawerComponent(
          <FakeContentForm
            contentData={contentData}
            onContentCreated={(newRow) => {
              setNewRowData(newRow);
              setDrawerVisible(false);
            }}
          />
        );
        break;
      case 'edit':
        setDrawerTitle('Edit Content');
        setDrawerComponent(<EditAndCreateContentForm contentData={contentData} formType="edit" />);

        setDrawerVisible(true);
        break;
      case 'relation':
        setDrawerTitle('Manage Relations');
        setDrawerComponent(
          <EditRelationForm passedContentData={contentData} setContentData={setContentData} />
        );
        setDrawerVisible(true);
        break;
      case 'tag':
        setDrawerTitle(`Add ${actualRelationName} to ${capitalize(connection?.to_model)}`);
        const newObj = {
          to_model: connection.to_model,
          model: connection.model,
          relation_type: connection.relation_type,
          known_as: connection.known_as,
          connection_type: connection.connection_type,
          tag_more: true,
        };
        setDrawerComponent(
          <Contents
            // selectedRowKeys={{}}
            connection={newObj}
          />
        );
        //   /* setDrawerComponent(<TableGenerator
        //                 oldSelectedRowKeys={selectedRowKeys}
        //                 connection={newObj}
        //                 modelName={modelName}
        //                 onActionClick={(obj) => {
        //                     setContentData(obj);
        //                 }}
        //                 newRowData={newRowData}
        //                 newSelectedRowKeys={(keys) => {
        //                     setNewSelectedRowKeys(keys);
        //                 }}
        //             />) */
        setDrawerVisible(true);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentData]);

  const navigate = useNavigate();

  return (
    <MainLayout
      title={<span style={{ textTransform: 'capitalize' }}>{modelName}</span>}
      breadcrumList={['Content']}
      extra={
        params?.model !== undefined ? (
          <div>
            {/* <Title level={5} className={styles.dropDownLabel} style={{ margin: '0 10px 0 0' }}>Tenant Filter</Title>
                  <FixedListSelector /> */}

            {newSelectedRowKeys?.length > 0
              ? [
                  <Button
                    icon={<TagsOutlined />}
                    loading={loadingTag}
                    onClick={(e) => {
                      fetchNewSelectedRowKeys(newSelectedRowKeys);
                    }}
                  >
                    {`TAG SELECTED ${actualRelationName}`.toUpperCase()}
                  </Button>,
                ]
              : null}

            {connection === undefined || connection?.tag_more
              ? [
                  <Button
                    onClick={(e) => {
                      setContentData({
                        action: 'fake',
                        model: modelName,
                      });
                      setDrawerVisible(true);
                    }}
                  >
                    FAKE DATA GENERATOR
                  </Button>,
                  <Divider type="vertical" />,
                  <Button
                    type="primary"
                    icon={<PlusCircleOutlined />}
                    onClick={(e) => {
                      setContentData({
                        action: 'create',
                        model: modelName,
                      });
                      setDrawerVisible(true);
                    }}
                  >
                    {`CREATE NEW ${modelName}`.toUpperCase()}
                  </Button>,
                ]
              : null}
          </div>
        ) : null
      }
    >
      {params?.model === undefined ? (
        <Empty description={<span>No Model Found to Store your Contents</span>}>
          <Button
            type="primary"
            onClick={() => {
              navigate('/console/models');
            }}
          >
            Create A Model Now
          </Button>
        </Empty>
      ) : (
        <div className="mt-3">
          <TableGenerator
            connection={connection}
            fetchIntersectResult={fetchIntersectResult}
            modelName={modelName}
            onActionClick={(obj) => {
              setContentData(obj);
              setDrawerVisible(true);
            }}
            newRowData={newRowData}
            setNewSelectedRowKeys={(keys) => {
              setNewSelectedRowKeys(keys);
            }}
          />
        </div>
      )}

      <Drawer // Default Drawer
        title={drawerTitle}
        width="75%"
        bodyStyle={{ padding: '5px 24px 24px' }}
        closable
        destroyOnClose={true}
        onClose={onDrawerClose}
        visible={drawerVisible}
        extra={<Button type="link" onClick={onDrawerClose}>{`< Back`}</Button>}
      >
        {drawerComponent}
      </Drawer>
    </MainLayout>
  );
};

export default Contents;
