import {
  Avatar,
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  Layout,
  PageHeader,
  Row,
  Tabs,
  Tag,
  Typography,
} from 'antd';
import { useEffect, useRef, useState } from 'react';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
// import Terminal from 'react-console-emulator';
import FixedListSelector from 'components/inputs/FixedListSelector';
import { runtimeEnvList } from 'data/runtimeEnvList';
import {
  CloudFunctionType,
  Plugin_Type_Enum,
  useGetAllModelsQuery,
  useListPluginIdsQuery,
  useUpsertFunctionToProjectMutation,
} from 'generated/graphql';
import { successMessage } from 'utils/message';
import { SelectContent } from '../../../components/custom/SelectContent';
import CloudProviderDrawerComponent from './drawer/cloud-provider/CloudProviderDrawerComponent';
import { FuncEnvDynamicFormList } from './FuncEnvDynamicFormList';
import styles from './functionForm.module.scss';
import { GET_ALL_FUNCTION_INFO } from '../../../graphql/queries/functions';
import { Icon } from '@iconify/react';
import CodeEditor from './CodeEditor';
import { InboxOutlined } from '@ant-design/icons';
import Dragger from 'antd/es/upload/Dragger';

dayjs.extend(relativeTime);

const { Content } = Layout;
const { TabPane } = Tabs;

const { Title, Text } = Typography;
const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const validateMessages = {
  // eslint-disable-next-line no-template-curly-in-string
  required: '${label} is required!',
};

function FunctionForm({ functionData }: { functionData: CloudFunctionType }) {
  const {
    name,
    request,
    response,
    function_connected = true,
    function_provider_id,
    created_at,
    updated_at,
    runtime_config,
    env_vars,
  } = functionData || {};

  const terminal_ref = useRef();

  const [resModelList, setResModelList] = useState([]);
  const [isProviderDrawer, setIsProviderDrawer] = useState(false);
  const [payload, setPayload] = useState({});

  const avatarSrc = function_connected
    ? '/assets/function/svg/connected.svg'
    : '/assets/function/svg/disconnected.svg';

  const createdAt = dayjs().from(dayjs(created_at), true);
  const editedAt = dayjs().from(dayjs(updated_at), true);

  const onCloseDrawer = () => {
    setIsProviderDrawer(false);
  };

  // const { data: funcList } = useQuery(GET_ALL_FUNCTION_INFO);

  const { error, data: modelList } = useGetAllModelsQuery();
  if (error) console.log(error);

  let terminal = null;
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    terminal = terminal_ref.current;
  });

  useEffect(() => {
    if (modelList) {
      // console.log('Log: onFinish -> modelList', modelList)
      const resModelArr = modelList?.projectModelsInfo?.map((item) => item.name) ?? [];
      // console.log('Log: onFinish -> resModelArr', resModelArr)
      setResModelList([...resModelArr, 'JSON']);
    }
  }, [modelList]);

  const updateCache = (cache, { data }) => {
    const resData = data.upsertFunctionToProject;
    // console.log('Log: updateCache -> resData', resData)
    const existingData = cache.readQuery({
      query: GET_ALL_FUNCTION_INFO,
    })?.projectFunctionsInfo;
    // console.log('Log: updateCache -> existingData', existingData)

    cache.writeQuery({
      query: GET_ALL_FUNCTION_INFO,
      data: {
        projectFunctionsInfo: [
          ...existingData.map((item) => {
            if (item.name === functionData?.name) {
              return resData;
            }
            return item;
          }),
        ],
      },
    });
  };

  const [disconnectFunction, { loading: disconnectLoading }] = useUpsertFunctionToProjectMutation({
    onCompleted: (data) => {
      // console.log('Log: data', data)
      successMessage('Function Disconnected Successfully!');
      window.location.reload();
    },
    update: updateCache,
  });

  const [updateFunction, { loading: updateLoading }] = useUpsertFunctionToProjectMutation({
    onCompleted: (data) => {
      // console.log('Log: data', data)
      successMessage('Function Updated Successfully!');
    },
    update: updateCache,
  });

  const onDisconnect = async () => {
    await disconnectFunction({
      variables: {
        name,
        function_connected: false,
        update: true, // provider_config: funcConfig,
      },
    });
  };

  const onFinish = async (values) => {
    console.log('Log ~ file: FunctionForm.js ~ line 132 ~ onFinish ~ values', values);
    await updateFunction({
      variables: {
        name,
        ...values,
        env_vars: values?.env_vars?.length ? values?.env_vars : undefined, //! assign right after payload
        update: true,
      },
    });
  };

  /*   const onValuesChange = (values) => {
    if (values?.runtime_config) {
      setPayload({
        ...payload,
        runtime_config: {
          // @ts-ignore
          ...payload.runtime_config,
          ...values.runtime_config,
        },
      });
    } else {
      setPayload({
        ...payload,
        ...values,
      });
    }
  }; */

  const { data: pluginList, loading: pluginListLoading } = useListPluginIdsQuery({
    variables: {
      type: Plugin_Type_Enum.Function,
    },
    onCompleted: (data) => {},
  });

  // if (contentSiderList?.listModelData?.length <= 0) return <div style={{ margin: '30vh auto 0 auto' }}><EmptyContent model={target} setIsCreate={setIsCreate} /></div>
  if (!name) {
    return (
      <div style={{ margin: '35vh auto 0 auto' }}>
        <SelectContent />
      </div>
    );
  }

  return (
    <PageHeader title={`Function Configuration: ${name}`}>
      <Content key={name} className={styles.contentFormContainer}>
        <Tabs
          className={styles.tabs}
          defaultActiveKey="0"
          type="card"
          size="small"
          style={{ marginTop: 10 }}
        >
          <TabPane
            className={styles.tab}
            tab={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <Icon style={{ marginRight: '10px' }} fontSize={20} icon="gravity-ui:function" />
                Function Settings
              </span>
            }
            key="0"
          >
            <Form
              {...layout}
              name="content-form"
              layout="horizontal"
              onFinish={onFinish}
              validateMessages={validateMessages}
              initialValues={{ remember: false }}
              //onValuesChange={onValuesChange}
            >
              <Row>
                <Col span={24}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                      }}
                    >
                      <Text className={styles.subtitle}>Apito Function</Text>
                      <Title level={4} className={styles.title}>
                        {name}
                      </Title>
                    </div>
                    <img
                      src={avatarSrc}
                      style={{
                        margin: '25px 64px',
                        width: `${function_connected ? 54 : 68}px`,
                        height: 'auto',
                      }}
                      alt=""
                    />
                    {function_connected ? (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                        }}
                      >
                        <Text className={styles.subtitle}>Provider: {function_provider_id}</Text>
                        <Title level={4} className={styles.title}>
                          Logic Executioner: {function_provider_id || ''}
                        </Title>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          marginTop: '-15px',
                        }}
                      >
                        <Text className={styles.subtitle}>Cloud Provider</Text>
                        {function_provider_id ? (
                          <Tag>{function_provider_id}</Tag>
                        ) : (
                          <Button
                            type="primary"
                            onClick={() => {
                              // terminal.pushToStdout('Hello after 1 second!');
                              setIsProviderDrawer(true);
                            }}
                          >
                            CONNECT
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      marginTop: '-5px',
                    }}
                  >
                    {editedAt ? <Text type="secondary">Edited {editedAt} ago,&nbsp;</Text> : null}
                    {createdAt ? (
                      <Text type="secondary">
                        {' '}
                        Published <span> {createdAt} ago </span>
                      </Text>
                    ) : null}
                  </div>
                </Col>
              </Row>

              <Row gutter={24} style={{ marginTop: '20px' }}>
                <Col span={8}>
                  <Form.Item>
                    <Text className={styles.title_2}>Function Provider</Text>
                  </Form.Item>

                  <Form.Item
                    name={['runtime_config', 'runtime']}
                    label="Runtime Environment"
                    initialValue={runtime_config?.runtime}
                  >
                    <FixedListSelector
                      value={function_provider_id}
                      list={runtimeEnvList || []}
                      islabelValue
                    />
                  </Form.Item>

                  <Form.Item
                    initialValue={function_provider_id}
                    name="function_provider_id"
                    label="Execution Provider"
                    rules={[{ message: 'Execution Provider is required', required: true }]}
                  >
                    <FixedListSelector list={pluginList?.listPluginIds?.plugins} />
                  </Form.Item>

                  <Form.Item
                    name="request"
                    label="Request Parameter"
                    initialValue={request?.model}
                    rules={[{ required: true }]}
                  >
                    <FixedListSelector list={resModelList} />
                  </Form.Item>

                  <Form.Item
                    name="response"
                    label="Response Parameter"
                    initialValue={response?.model}
                    rules={[{ required: true }]}
                  >
                    <FixedListSelector list={resModelList} />
                  </Form.Item>

                  <Form.Item
                    label="Environment Variable"
                    name="env_vars"
                    initialValue={
                      env_vars?.length
                        ? env_vars.map(({ key, value }) => ({
                            key,
                            value,
                          }))
                        : []
                    }
                  >
                    <FuncEnvDynamicFormList />
                  </Form.Item>
                </Col>
                {function_provider_id ? (
                  <Col span={8}>
                    <Form.Item>
                      <Text className={styles.title_2}>Function Configurations</Text>
                    </Form.Item>

                    <Form.Item
                      name={['runtime_config', 'handler']}
                      label="Handler Name"
                      initialValue={runtime_config?.handler}
                    >
                      <Input placeholder="Handler Name" />
                    </Form.Item>

                    <Form.Item
                      name={['runtime_config', 'memory']}
                      label="Memory Allocation"
                      initialValue={runtime_config?.memory}
                    >
                      <Input type="number" placeholder="Memory Allocation" />
                    </Form.Item>

                    <Form.Item
                      name={['runtime_config', 'time_out']}
                      label="Request Timeout"
                      initialValue={runtime_config?.time_out}
                    >
                      <Input type="number" placeholder="Request Timeout" />
                    </Form.Item>
                  </Col>
                ) : null}
                <Col span={8}>
                  <Dragger>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag ZIP file to this area to upload</p>
                    <p className="ant-upload-hint">
                      Support for a single or bulk upload. Upload Only ZIP file
                    </p>
                  </Dragger>

                  {/* <Terminal
                noDefault
                dangerMode
                noAutomaticStdout
                styleEchoBack="textonly"
                ref={terminal_ref}
                // welcomeMessage={"Welcome to the React terminal!"}
                // promptLabel={"me@React:~$"}
                readOnly
                disabled
              /> */}
                </Col>
              </Row>

              <Row>
                <div style={{ display: 'flex' }}>
                  <Button type="primary" htmlType="submit" loading={updateLoading}>
                    UPDATE
                  </Button>
                  {function_connected ? (
                    <>
                      <Divider type={'vertical'} />
                      <Button className="ml-2" onClick={onDisconnect} loading={disconnectLoading}>
                        DISCONNECT FUNCTION
                      </Button>
                    </>
                  ) : null}
                </div>
              </Row>
            </Form>
          </TabPane>
          <TabPane
            className={styles.tab}
            tab={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <Icon style={{ marginRight: '10px' }} fontSize={20} icon="icon-park-outline:log" />
                Request & Response Logs
              </span>
            }
            key="1"
          >
            {/* <CodeEditor /> */}
          </TabPane>
        </Tabs>
        <Drawer
          title="Connect to Function Provider"
          placement="right"
          width={320}
          closable
          onClose={onCloseDrawer}
          visible={isProviderDrawer}
          extra={<Button type="link" onClick={onCloseDrawer}>{`< Back`}</Button>}
        >
          <CloudProviderDrawerComponent functionData={functionData} />
        </Drawer>
      </Content>
    </PageHeader>
  );
}

export default FunctionForm;
