import { Button, Drawer } from 'antd';
import { Field_Type_Enum } from 'generated/graphql';
import { capitalize } from 'lodash';
import React, { useState } from 'react';
import { FieldArrayProps } from 'types/model';
import SingleField from '../SingleField';
import CreateField from './CreateField';
import { fieldArr } from '../data';

const CreateFieldDrawer = ({ repeatedFieldIdentifier }: { repeatedFieldIdentifier: string }) => {
  const [isFieldConfigDrawer, setIsFieldConfigDrawer] = useState(false);
  const [drawerField, setDrawerField] = useState<FieldArrayProps>({});

  const getDrawerTitle = () => {
    switch (drawerField?.field_sub_type) {
      case 'dropdown':
        return 'Dropdown List';
      case 'dynamicList':
        return 'Dynamic List';
      case 'multiSelect':
        return 'Multi Select';
      default:
        return `${capitalize(drawerField?.field_type ?? '')}`;
    }
  };

  const openAddFieldDrawer = (index) => {
    setDrawerField(fieldArr[index]);
    setIsFieldConfigDrawer(true);
  };

  return (
    <div>
      {fieldArr.map(
        (field, i) =>
          !(repeatedFieldIdentifier && field.field_type === 'relation') && (
            <SingleField field={field} key={i} index={i} onOpenDrawer={openAddFieldDrawer} />
          )
      )}
      <Drawer
        title={`Add ${getDrawerTitle()}`}
        width={340}
        closable
        onClose={() => setIsFieldConfigDrawer(false)}
        visible={isFieldConfigDrawer}
        extra={
          <Button type="link" onClick={() => setIsFieldConfigDrawer(false)}>{`< Back`}</Button>
        }
      >
        <CreateField
          field={drawerField}
          setIsFieldConfigDrawer={setIsFieldConfigDrawer}
          repeatedFieldIdentifier={repeatedFieldIdentifier}
        />
      </Drawer>
    </div>
  );
};

export default CreateFieldDrawer;
